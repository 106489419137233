import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { TournamentStatus } from '../types'

export const useTournamentsStore = defineStore('tournaments', () => {
  const app = useNuxtApp()
  const { settings } = storeToRefs(useSettingsStore())

  const { data: tournamentsCount, execute: fetchTournamentsCounters } =
    useStFetch('/rat-race/find', {
      method: 'post',
      body: computed(() => ({
        params: {
          language: app.$i18n.locale.value as any,
          status: ['open'] as TournamentStatus[],
        },
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' } as const],
        },
      })),
      transform: (response) => response.paging.total,
      immediate: false,
      watch: false,
    })

  const isEnabledTournaments = computed(
    () => !!settings.value?.frontRatRaceEnabled,
  )

  const isEnabledActiveTournaments = computed(
    () => isEnabledTournaments.value && !!tournamentsCount.value,
  )

  watch(
    () => settings.value?.frontRatRaceEnabled,
    (newValue) => {
      if (newValue) {
        fetchTournamentsCounters()
      }
    },
    {
      immediate: true,
    },
  )

  return {
    tournamentsCount,
    isEnabledTournaments,
    isEnabledActiveTournaments,
  }
})
